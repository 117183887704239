import { AttributeValue } from "@msdyn365-commerce/retail-proxy";
import { IAttributeGroupMap, IAttributeGroupMapAttribute } from "./attributes-map";
import { getAttributeStringOrDefault } from "./";

/**
 * Returns the index of the most used attribute name in the given array of product specifications named from attribute map.
 */
export function getMostUsedAttributeNameIndex(attributes: AttributeValue[] | undefined, attributeGroup: IAttributeGroupMap, notFoundDefaultValue: string) {
    const usedNameIndicies: number[] = [];
    const attributeGroupsWithNameArray = attributeGroup.attributes?.filter(x => typeof x !== 'string' && x.name instanceof Array) as IAttributeGroupMapAttribute[];
    const arrayOfNameArrays = attributeGroupsWithNameArray?.map(x => x.name) as (string[])[];

    if (arrayOfNameArrays && arrayOfNameArrays.length > 0) {
        for (let i = 0; i < arrayOfNameArrays.length; i++) {
            if (!arrayOfNameArrays[i]) {
                continue;
            }
            for (let y = 0; y < arrayOfNameArrays[i]?.length; y++) {
                const value = getAttributeStringOrDefault(attributes,
                    arrayOfNameArrays[i][y],
                    attributeGroup.defaultValue || notFoundDefaultValue);

                if (!attributeGroup.nullValues?.includes(value)) {
                    usedNameIndicies.push(y);
                }
            }
        }
    }

    // Get the number that appears the most in 'usedNameIndicies' array
    const mostCommonNameIndex = usedNameIndicies.reduce((acc, curr, i, arr) => {
        const count = arr.filter((x) => x === curr).length;
        return count > acc.count ? { count, index: curr } : acc;
    }, { count: 0, index: 0 }).index;

    return mostCommonNameIndex;
}
