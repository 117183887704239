import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { IProductRefinerHierarchy } from '@msdyn365-commerce/commerce-entities';
import { Buffer } from 'buffer/';

export function decodeBase64String(base64String: string): string {
    return Buffer.from(base64String, 'base64').toString('utf-8');
}

export function encodeBase64String(string: string): string {
    return Buffer.from(string).toString('base64');
}

export function isBase64Encoded(str: string | undefined): boolean {
    if (!str || str === '' || str.trim() === '') {
        return false;
    }

    // Base64 strings should have a length that is a multiple of 4
    if (str.length % 4 !== 0) {
        return false;
    }

    // A basic regex for base64 strings
    const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;

    return base64Regex.test(str);
}

export function isBase64EncodePrefixed(attribute: AttributeValue | IProductRefinerHierarchy | undefined): boolean {
    if (!attribute) {
        return false;
    }

    if (attribute.KeyName?.toLowerCase().startsWith('{base64}:')) {
        return true;
    }

    if ('Name' in attribute) {
        return !!attribute?.Name?.toLowerCase().startsWith('{base64}:');
    }

    return false;
}
