import { AttributeValue } from "@msdyn365-commerce/retail-proxy";

export const sortAttributes = (a: AttributeValue, b: AttributeValue): number => {
    const a_order = a.ExtensionProperties?.find(prop => prop.Key === 'ATTRORDER')?.Value?.DecimalValue;
    const b_order = b.ExtensionProperties?.find(prop => prop.Key === 'ATTRORDER')?.Value?.DecimalValue;

    if (!a_order) {
        return 1;
    }
    if (!b_order) {
        return -1;
    }

    if (a_order < b_order) {
        return -1;
    }
    if (a_order > b_order) {
        return 1;
    }
    return 0;
};

export const sortAttributesByGroupOrder = (a: AttributeValue, b: AttributeValue): number => {
    const a_order = a.ExtensionProperties?.find(prop => prop.Key === 'ATTRGROUPORDER')?.Value?.DecimalValue;
    const b_order = b.ExtensionProperties?.find(prop => prop.Key === 'ATTRGROUPORDER')?.Value?.DecimalValue;

    if (!a_order) {
        return 1;
    }
    if (!b_order) {
        return -1;
    }

    if (a_order < b_order) {
        return -1;
    }
    if (a_order > b_order) {
        return 1;
    }
    return 0;
};
